import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import variables from '../variables';
import { useSession } from '../screens/Lobby/hooks/useSession';
import { GET_CURRENT_USER } from './queries';
import { JOIN_SESSION_MUTATION, POST_SESSION_MUTATION } from './mutations';

const { USER_POLLING_INTERVAL } = variables;

export const useCurrentUser = () => {
  const query = useQuery(GET_CURRENT_USER, {
    pollInterval: USER_POLLING_INTERVAL,
    fetchPolicy: "cache-and-network"
  });

  return {
    ...query,
    data: get(query, 'data.currentUser'),
  };
};

export const useJoinSession = (hasJoinedSession) => {
  const [error, setError] = useState(null);
  const currentUser = useCurrentUser();
  const session = useSession();
  const userId = get(currentUser, 'data.id', null);
  const sessionId = get(session, 'id', null);
  const _error = 'Something went wrong. Please try again.'

  const [joinSession, { called, loading, data }] = useMutation(JOIN_SESSION_MUTATION, {
    variables: {
      sessionId,
      userId
    },
    onCompleted: ({ createSessionRoom }) => {
      // P-API doesn't return an error if it can't join room,
      // so handling it in state here as a work around
      if (!createSessionRoom) setError(_error);
    },
    onError: () => setError(_error),
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error, setError]);

  return {
    called: hasJoinedSession ? called : false,
    data: hasJoinedSession ? get(data, 'joinSessionRoom', {}) : {},
    error,
    joinSession,
    sessionLoading: loading
  }
};

export const useStorePostSession = () => {
  const [storePostSession, { data, loading, error }] = useMutation(POST_SESSION_MUTATION);

  return {
    storePostSession,
    data,
    loading,
    error
  }
};
