import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const context = React.createContext({});

const withSession = WrappedComponent => ({ ...props }) => {
  const { participant } = props;
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isAudioSubscribed, setAudioSubscribed] = useState(false);
  const [isVideoSubscribed, setVideoSubscribed] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackPubsToTracks = useCallback(
    (trackMap) =>
      Array.from(trackMap.values())
        .map((publication) => publication.track)
        .filter((track) => track),
    []
  );

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks));
    setAudioTracks(trackPubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoSubscribed(true);
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      }
      if (track.kind === "audio") {
        setAudioSubscribed(true);
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoSubscribed(false);
        track.detach().forEach((el) => (el.srcObject = null));
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      }
      if (track.kind === "audio") {
        setAudioSubscribed(false);
        track.detach().forEach((el) => (el.srcObject = null));
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, trackPubsToTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, videoRef]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, audioRef]);

  const sessionContext = {
    audioRef,
    videoRef,
    participant,
  };

  return (
    <context.Provider value={sessionContext}>
      <WrappedComponent
        isAudioSubscribed={isAudioSubscribed}
        isVideoSubscribed={isVideoSubscribed}
        videoRef={videoRef}
        audioRef={audioRef}
        {...props}
      />
    </context.Provider>
  );
};

withSession.propTypes = {
  participant: PropTypes.object.isRequired,
};

export default withSession;
