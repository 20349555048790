import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";
import variables from "../variables";

const _propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

const LoadingSpinner = ({ loading = false, size = 'small' }) => (
  <div>
    <ClipLoader
      css={`margin: 10px`}
      size={size === 'large' ? 60 : 20}
      color={variables.white}
      loading={loading}
    />
  </div>
);

LoadingSpinner.propTypes = _propTypes;

export default LoadingSpinner;