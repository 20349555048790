import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import get from "lodash/get";
import PropTypes from "prop-types";
import Button from "./Button";
import MenuDetail from "./MenuDetail";
import MenuHeader from "./MenuHeader";
import variables from "../variables";
import { useSession } from "../screens/Lobby/hooks/useSession";
import { H4 } from "../components/Typography";
import { AppContext } from "../context";

const _propTypes = {
  setShowModal: PropTypes.func.isRequired,
};

const Account = ({ setShowModal }) => {
  // prettier-ignore
  const { currentUser, setToken, logoutWithFirebase, isIframe } = useContext(AppContext);
  const upcomingSessions = get(currentUser, "data.nextSessions", []);
  const userLoading = get(currentUser, "loading", false);
  const name = get(currentUser, "data.name", "");
  const email = get(currentUser, "data.email", "");
  const session = useSession();
  const sessions = get(currentUser, "data.nextSessions", []);
  const nextSession = sessions.length
    ? sessions.find((session) => session.next)
    : null;
  const supportEmailHref = `mailto:support@sphere.guide?subject=${
    nextSession ? `Session%20D-${nextSession.id}` : `Session%20Support`
  }`;

  const handleLogout = useCallback(() => {
    setToken(null);
    logoutWithFirebase();
  }, [setToken, logoutWithFirebase]);

  const handleContactSupport = useCallback(() => {
    window.location.href = supportEmailHref;
  }, [supportEmailHref]);

  const handleCloseModal = useCallback(
    () => setShowModal(false),
    [setShowModal]
  );

  return (
    <Container>
      <MenuHeader onClose={handleCloseModal}>
        <MenuHeaderChildren isIframe={isIframe}>
          Your Account
        </MenuHeaderChildren>
      </MenuHeader>
      <Name>{name}</Name>
      <Email>{email}</Email>
      <MenuDetail
        loading={userLoading}
        title={
          session.isActive ? "You have an active session" : "Upcoming Sessions"
        }
        value={!session.isActive ? upcomingSessions.length : ""}
      />
      <MenuControls>
        <Button
          style={{ marginBottom: 10 }}
          onClick={handleContactSupport}
          cta={false}
        >
          Contact Support
        </Button>
        <Button onClick={handleLogout} cta={false}>
          Logout
        </Button>
      </MenuControls>
    </Container>
  );
};

Account.propTypes = _propTypes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .buttons {
    height: 90px;
  }
`;

const MenuControls = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const MenuHeaderChildren = styled.div`
  --size: 50px;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: var(--size);
  margin-left: ${({ isIframe }) => (isIframe ? "var(--size)" : 0)};
`;

const Name = styled(H4)`
  color: ${variables.white};
  margin: 0;
`;
const Email = styled(H4)`
  color: ${variables.grey_primary};
`;

export default Account;
