import React, { useCallback, useState, useEffect } from "react";

export const IS_VIDEO_ENABLED = false; // { height: 720, frameRate: 24, width: 1280 }
export const IS_AUDIO_ENABLED = true;

const withTwilio = (WrappedComponent) => ({ roomData, ...props }) => {
  const [twilio, setTwilio] = useState(null);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [hasDisconnected, setDisconnected] = useState(false);
  const { roomName, token } = roomData;

  useEffect(() => setTwilio(window.Twilio.Video), []);

  const participantConnected = useCallback(
    (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    },
    [setParticipants]
  );

  const participantDisconnected = useCallback(
    (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    },
    [setParticipants]
  );

  const roomDisconnected = useCallback(
    (onSuccess) => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          if (onSuccess) onSuccess();
          return null;
        } else {
          return currentRoom;
        }
      });
    },
    [setRoom]
  );

  useEffect(() => {
    if (token) {
      twilio
        .connect(token, {
          name: roomName,
          audio: IS_AUDIO_ENABLED,
          video: IS_VIDEO_ENABLED,
          bandwidthProfile: {
            video: {
              mode: "collaboration",
              maxTracks: 2,
              dominantSpeakerPriority: "standard",
              renderDimensions: {
                high: { height: 1080, width: 1980 },
                standard: { height: 720, width: 1280 },
                low: { height: 176, width: 144 },
              },
            },
          },
          dominantSpeaker: true,
          maxAudioBitrate: 16000,
          preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          networkQuality: { local: 1, remote: 1 },
        })
        .then((room) => {
          setRoom(room);
          room.participants.forEach(participantConnected);
          room.on("participantConnected", participantConnected);
          room.on("participantDisconnected", participantDisconnected);
          room.on("disconnected", () => setDisconnected(true));
        })
        .catch((e) => console.log("twilio connect error: ", e.message));

      return () => roomDisconnected();
    }
  }, [
    roomName,
    token,
    twilio,
    participantConnected,
    participantDisconnected,
    roomDisconnected,
  ]);

  if (!twilio) return null;

  return (
    <WrappedComponent
      disconnectedProps={{ hasDisconnected, setDisconnected, roomDisconnected }}
      participants={participants}
      room={room}
      twilio={twilio}
      {...props}
    />
  );
};

export default withTwilio;
