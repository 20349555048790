import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Error, H4 } from "../components/Typography";
import { useMutation } from "@apollo/react-hooks";
import {
  LOGIN_MUTATION,
  REQUEST_PASSWORD_RESET_MUTATION,
} from "../network/mutations";
import { isEmailValid } from "../lib/helperMethods";
import variables from "../variables";

// Components
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import SphereLogo from "../components/SphereLogo";

const Auth = ({ setJWT, loading }) => {
  const formRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setError] = useState(null);
  const [mode, setMode] = useState(MODES.login);
  const [feedback, setFeedback] = useState(null);
  const [login, loginResult] = useMutation(LOGIN_MUTATION, {
    variables: { email, password },
    onError: setError,
  });
  const [requestResetPassword, requestResetPasswordResult] = useMutation(
    REQUEST_PASSWORD_RESET_MUTATION,
    {
      variables: { email },
      onCompleted: ({ requestResetPassword: feedback }) => {
        feedback
          ? setFeedback({ message: `Email Sent` })
          : setFeedback({
              error: true,
              message: `Something went wrong. Check your email and try again.`,
            });
      },
    }
  );

  const isLoading = useMemo(() => {
    // don't need a default case
    // eslint-disable-next-line
    switch (mode) {
      case MODES.login:
        return [loading, loginResult.loading].some(Boolean);
      case MODES.forgotPassword:
        return [requestResetPasswordResult.loading].some(Boolean);
    }
  }, [mode, loading, loginResult.loading, requestResetPasswordResult.loading]);

  const finePrint = useMemo(() => TEXT[mode].finePrint, [mode]);

  const isDisabled = useMemo(() => {
    const validEmail = isEmailValid.test(email);
    // don't need a default case
    // eslint-disable-next-line
    switch (mode) {
      case MODES.login:
        return ![validEmail, password.length].every(Boolean);
      case MODES.forgotPassword:
        return !validEmail;
    }
  }, [mode, email, password]);

  useEffect(() => {
    const { data } = loginResult;
    if (data && data.login) setJWT(data.login.jwt);
  }, [loginResult, setJWT]);

  useEffect(() => setPassword(""), [loginResult.error]);

  useEffect(() => {
    if (feedback) {
      setTimeout(() => {
        setFeedback(null);
      }, 3500);
    }
  }, [feedback, setFeedback]);

  useLayoutEffect(() => {
    const { style } = formRef.current;
    if (mode === MODES.forgotPassword) {
      style.height = "50px";
    } else {
      style.height = "100px";
    }
  }, [mode, formRef]);

  const handleLogin = useCallback(
    (event) => {
      event.preventDefault();
      if (email && password) login();
    },
    [email, password, login]
  );

  const handleForgotPassword = useCallback(
    (event) => {
      event.preventDefault();
      email && requestResetPassword();
    },
    [email, requestResetPassword]
  );

  const handleSubmit = useMemo(() => {
    return mode === MODES.login ? handleLogin : handleForgotPassword;
  }, [mode, handleLogin, handleForgotPassword]);

  return (
    <Container>
      <LogoContainer isLoading={isLoading}>
        <SphereLogo />
      </LogoContainer>

      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <TextFields ref={formRef}>
          <TextInput
            attrs={{ type: "email", placeholder: "Email" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextInput
            attrs={{ type: "password", placeholder: "Password" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </TextFields>

        <FinePrint
          isError={feedback?.error}
          isLink={!feedback}
          onClick={() => {
            setMode((mode) =>
              mode === MODES.login ? MODES.forgotPassword : MODES.login
            );
          }}
        >
          {feedback ? feedback?.message : finePrint}
        </FinePrint>

        {loginError &&
          loginError.graphQLErrors.map(({ message }, i) => (
            <Error key={i} style={{ textAlign: "unset" }}>
              {message}
            </Error>
          ))}

        <Button
          loading={isLoading}
          cta={true}
          type="submit"
          disabled={isDisabled}
        >
          {TEXT[mode].button}
        </Button>
      </Form>
    </Container>
  );
};

const MODES = {
  login: "login",
  forgotPassword: "forgotPassword",
};

const TEXT = {
  login: {
    finePrint: `Forgot Password?`,
    button: `Login`,
  },
  forgotPassword: {
    finePrint: `Back to Login`,
    button: `Reset Password`,
  },
};

const Container = styled.div`
  background: black;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  position: relative;
  top: ${({ isLoading }) => (isLoading ? 80 : 0)}px;
  transition: top 0.8s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Form = styled.form`
  width: 300px;
  margin: 20px;
  opacity: ${({ isLoading }) => Number(!isLoading)};
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
`;

const TextFields = styled.div`
  overflow: hidden;
  transition: all 500ms ease;
`;

const FinePrint = styled(H4)`
  color: ${({ isError }) =>
    isError ? variables.error : variables.grey_primary};
  cursor: ${({ isLink }) => isLink && "pointer"};
  font-size: 14px;
  text-decoration: ${({ isLink }) => isLink && "underline"};
`;

export default Auth;
