import React from "react";
import PropTypes from 'prop-types';
import variables from "../variables";
import styled from "styled-components";

const _propTypes = {
  attrs: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  style: PropTypes.object,
};

const TextInput = ({ attrs = {}, onChange, value = "", style }) => {
  const {
    autoFocus = false,
    disabled = false,
    maxLength = 999,
    minLength = 0,
    name = "",
    placeholder = "",
    type = "text",
  } = attrs;

  return (
    <Container style={style}>
      <Input
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </Container>
  );
};

TextInput.propTypes = _propTypes;

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 2px solid ${variables.white};
  padding-bottom: 5px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: ${variables.white};
  font-size: 18px;
  outline: "none";
  width: inherit;
  &:focus {
    outline: none;
  }
`;

export default TextInput;
