import styled, { css } from 'styled-components';
import variables from '../variables';

const { breakpoints: { small } } = variables;

const headingCSS = css`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const H1 = styled.h1`
  font-size: 40px;
  line-height: ${54 / 48};
  ${headingCSS};

  @media(min-width: ${small}) {
    font-size: 60px;
  }
`;

export const H2 = styled.h2`
  font-size: 24px;
  line-height: ${40 / 36};
  ${headingCSS};

  @media(min-width: ${small}) {
    font-size: 36px;
  }
`;

export const H3 = styled.h3`
  font-size: 24px;
  line-height: ${34 / 24};
  ${headingCSS};

  @media(min-width: ${small}) {
    font-size: 28px;
  }
`;

export const H4 = styled.h4`
  font-size: 20px;
  line-height: ${28 / 20};
  ${headingCSS};

  @media(min-width: ${small}) {
    font-size: 24px;
  }
`;

export const Error = styled(H4)`
  color: ${variables.error};
  font-size: 18px;
  text-align: center;

  @media(min-width: ${small}) {
    font-size: 14px;
  }
`;
