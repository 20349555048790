import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/compat/app";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/react-hooks";
import { getFirebase } from "./lib/getEnvConstants";
import createApolloClient from "./network/apollo";
import CustomContextProvider from "./context";
import theme from "./theme";
import "firebase/compat/auth";

import "./index.css";

// Screens
import Lobby from "./screens/Lobby";
import Auth from "./screens/Auth.js";

// Firebase Config
firebase.initializeApp(getFirebase(process.env.NODE_ENV));

const localToken = localStorage.getItem("token");

const client = createApolloClient();

// Root Element
const layout = document.getElementById("layout");

export function App() {
  const [JWT, setJWT] = useState(null);
  const [token, setToken] = useState(localToken);
  useEffect(() => {
    layout.style.height = window.innerHeight + "px";

    /* Helpscout beacon */
    /* dont load if app is in iframe as parent window already has it */
    if (window.location === window.parent.location) {
      const helpscoutScript = document.createElement("script");
      helpscoutScript.src = "/helpscout.js";
      helpscoutScript.type = "text/javascript";
  
      if (!window.Beacon) {
        document.body.appendChild(helpscoutScript);
      }
      return () => {
        document.body.removeChild(helpscoutScript);
      };
    }
  }, []);

  useEffect(() => {
    if (JWT && !token) {
      loginWithFirebase(JWT);
      setJWT(null);
    }
  }, [JWT, token]);

  const loginWithFirebase = async (jwt) => {
    firebase
      .auth()
      .signInWithCustomToken(jwt)
      .then((userCredential) => {
        const {
          user: { refreshToken },
        } = userCredential;
        const user = firebase.auth().currentUser;
        localStorage.setItem("refreshToken", refreshToken);
        if (user) {
          user
            .getIdToken()
            .then((firebaseToken) => {
              localStorage.setItem("token", firebaseToken);
              setToken(firebaseToken);
            })
            .catch((error) => {
              console.log("getIdToken error", error);
            });
        }
      })
      .catch((e) => console.log("ERROR SIGNING IN WITH CUSTOM TOKEN:", e));
  };

  const logoutWithFirebase = () => {
    firebase.auth().signOut();
    setJWT(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  };
  const authProps = {
    token,
    setToken,
    logoutWithFirebase,
  };

  return (
    <ThemeProvider theme={theme}>
      {token ? (
        <CustomContextProvider authProps={authProps}>
          <Lobby />
        </CustomContextProvider>
      ) : (
        <Auth setJWT={setJWT} />
      )}
    </ThemeProvider>
  );
}

const AppHOC = () => {
  // give App access to apollo client
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};
ReactDOM.render(<AppHOC />, layout);
