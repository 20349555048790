import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      email
      type
      image
      nextSessions {
        id
        date
        endDate
        length
        type
        next
        discovery
        guide {
          id
          first_name
          image
        }
        seeker {
          id
          name
        }
        __typename
      }
    }
  }
`;
