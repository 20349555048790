import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import fetch from "isomorphic-unfetch";
import firebase from "firebase/compat/app";

import { parseJwt } from "../lib/helperMethods";
import { getUri } from "../lib/getEnvConstants";

const NODE_ENV = process.env.NODE_ENV;
const uri = getUri(NODE_ENV);

const httpLink = createHttpLink({
  uri,
  credentials: "same-origin",
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  let authorization = "";

  if (token) {
    const tokenObj = parseJwt(token);
    // If not expired
    if (!(tokenObj.exp < Date.now() / 1000)) {
      authorization = `Bearer ${token}`;
    } else {
      const user = firebase.auth().currentUser;
      if (user) {
        // Force refresh the token since it has expired
        user.getIdToken(true).then((newToken) => {
          localStorage.setItem("token", newToken);
          authorization = `Bearer ${newToken}`;
        });
      }
    }
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

const createApolloClient = (initialState = {}) =>
  new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // TODO
        }
        if (networkError) {
          // TODO
        }
      }),
      authLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }).restore(initialState),
  });

export default createApolloClient;
