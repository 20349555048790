import React, { useState, createContext, useEffect } from "react";
import { useCurrentUser } from "./network/hooks";

export const AppContext = createContext({});

export const CustomContextProvider = ({ children, authProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isIframe, setIsIFrame] = useState(false);
  const currentUser = useCurrentUser();

  const { token, setToken, logoutWithFirebase } = authProps;
  
  useEffect(() => {
    setIsIFrame(window.location !== window.parent.location);
  }, []);

  useEffect(() => {
    if (!currentUser.data) {
      setIsLoading(true);
      // start polling quickly to update user
      currentUser.startPolling(1000);
    } else if (currentUser.data) {
      setIsLoading(false);
      currentUser.stopPolling();
    }
  }, [currentUser.data, currentUser, isLoading]);

  const value = {
    currentUser,
    isLoading,
    isIframe,
    setIsLoading,
    logoutWithFirebase,
    token,
    setToken,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default CustomContextProvider;
