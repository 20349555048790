import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import ParticipantLocal from "./ParticipantLocal";
import ParticipantRemote from "./ParticipantRemote";
import ParticipantInfo from "./ParticipantInfo";
import withTwilio from "./withTwilio";
import variables from "../variables";

const { HEADER_HEIGHT } = variables;

const _propTypes = {
  disconnectedProps: PropTypes.object.isRequired,
  hasJoinedSession: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  participants: PropTypes.array.isRequired,
  room: PropTypes.object,
  session: PropTypes.object,
  setJoinedSession: PropTypes.func.isRequired,
  twilio: PropTypes.object.isRequired,
};

const Session = ({
  disconnectedProps,
  hasJoinedSession,
  loading,
  participants,
  room,
  session,
  setJoinedSession,
  twilio,
}) => {
  const counterpartJoined = Boolean(room && participants.length);

  const renderRemoteParticipant = () =>
    counterpartJoined ? (
      participants.map((participant, index) => (
        // passing only the sid as the react key causes duplicate key warning when rejoining session
        <ParticipantRemote
          key={`${participant.sid}-${index}`}
          participant={participant}
          session={session}
          hasDisconnected={disconnectedProps.hasDisconnected}
        />
      ))
    ) : (
      <ParticipantInfo isVideoSubscribed={false} session={session} waiting />
    );

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner loading={loading} size="large" />
      </LoadingContainer>
    );
  }

  return (
    <SessionContainer hasJoinedSession={hasJoinedSession}>
      {hasJoinedSession ? renderRemoteParticipant() : null}

      {room && (
        <ParticipantLocal
          disconnectedProps={disconnectedProps}
          counterpartJoined={counterpartJoined}
          participant={room.localParticipant}
          room={room}
          setJoinedSession={setJoinedSession}
          session={session}
          twilio={twilio}
        />
      )}
    </SessionContainer>
  );
};

Session.propTypes = _propTypes;

export default withTwilio(Session);

const SessionContainer = styled.div`
  align-items: center;
  background: ${variables.black};
  justify-content: center;
  opacity: ${({ hasJoinedSession }) => Number(hasJoinedSession)};
  transition: opacity 1500ms ease;
  position: ${({ hasJoinedSession }) => hasJoinedSession ? "absolute" : "relative"};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingContainer = styled.div`
  height: min-content;
  margin: auto;
  width: min-content;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
`;
