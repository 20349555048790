import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withSession from "./withSession";
import PostSession from "./PostSession";
import SessionControls from "./SessionControls";
import variables from "../variables";
import { IS_AUDIO_ENABLED } from "./withTwilio";
import { useCurrentUser } from '../network/hooks';

const { breakpoints: { medium } } = variables;

const ParticipantLocal = ({ counterpartJoined, audioRef, videoRef, ...props }) => {
  const { hasDisconnected, setDisconnected } = props.disconnectedProps;
  const { setJoinedSession } = props;
  const [selfView, setSelfView] = useState(counterpartJoined);
  const currentUser = useCurrentUser();
  useEffect(() => setSelfView(!counterpartJoined), [counterpartJoined]);

  const handleDisconnect = useCallback(() => {
    setJoinedSession(false);
    setDisconnected(false);
    currentUser.refetch();
  }, [currentUser, setJoinedSession, setDisconnected]);

  const toggleSelfView = useCallback(() => {
    const remoteParticipant = document.querySelector(`#remote-participant`);

    if (remoteParticipant) {
      remoteParticipant.style.opacity = Number(selfView);
    }

    setSelfView(!selfView);
  }, [setSelfView, selfView]);

  return (
    <LocalVideoContainer id="local-participant">
      {hasDisconnected ? (
        <PostSession {...props} handleDisconnect={handleDisconnect} />
      ) : (
        <>
          <LocalVideo
            ref={videoRef}
            selfView={selfView}
            onClick={toggleSelfView}
          />
          <audio ref={audioRef} autoPlay={true} muted={!IS_AUDIO_ENABLED} />
          <SessionControls
            {...props}
            setSelfView={setSelfView}
            handleDisconnect={handleDisconnect}
          />
        </>
      )}
    </LocalVideoContainer>
  );
};

ParticipantLocal.propTypes = {
  counterpartJoined: PropTypes.bool.isRequired,
  videoRef: PropTypes.object.isRequired,
};

export default withSession(ParticipantLocal);

const LocalVideoContainer = styled.div`
  height: 100%;
  overflow: hidden !important;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const LocalVideo = styled.video`
  border-radius: ${({ selfView }) => (selfView ? "none" : "10px")};
  cursor: pointer;
  height: ${({ selfView }) => (selfView ? "auto" : "initial")};
  transition: all 300ms ease;
  transform: ${({ selfView }) => selfView && `translate(-50%, -50%)`};
  width: ${({ selfView }) => (selfView ? "auto" : "150px")};
  z-index: 3;
  position: absolute;
  left: ${({ selfView }) => (selfView ? "50%" : "auto")};
  top: ${({ selfView }) => (selfView ? "50%" : "auto")};
  bottom: ${({ selfView }) => (selfView ? "auto" : "20%")};
  right: ${({ selfView }) => (selfView ? "auto" : "5%")};

  @media (min-width: ${medium}px) {
    /* bottom: ${({ selfView }) => (selfView ? "auto" : "10%")}; */
    height: ${({ selfView }) => (selfView ? "100vh" : "auto")};
    width: ${({ selfView }) => (selfView ? "100vw" : "300px")};
  }
`;
