import React from "react";
import PropTypes from 'prop-types';
import variables from "../variables";
import styled from "styled-components";

const _propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

const MenuHeader = ({ children, onClose }) => (
  <Header>
    <span>{children}</span>{" "}
    {onClose && <Cross onClick={onClose}>&times;</Cross>}
  </Header>
);

MenuHeader.propTypes = _propTypes;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 30px;
  color: ${variables.white_primary};
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Cross = styled.span`
  font-size: 1.7em;
  cursor: pointer;
  line-height: 30px;
`;

export default MenuHeader;
