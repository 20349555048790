import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Account from "./Account";

const _propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

const Modal = ({ showModal, setShowModal }) => (
  <Container showModal={showModal} onClick={() => setShowModal(false)}>
    <MenuPane
      onClick={(evt) => evt.stopPropagation()}
      showModal={showModal}
      position={0}
    >
      <Account setShowModal={setShowModal} />
    </MenuPane>
  </Container>
);

Modal.propTypes = _propTypes;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: background 1s cubic-bezier(0.24, 1, 0.32, 1);
  background: rgba(12, 12, 12, ${({ showModal }) => (showModal ? 0.5 : 0)});
  pointer-events: ${({ showModal }) => (showModal ? "auto" : "none")};
`;

const MenuPane = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 375px;
  padding: 15px;
  position: absolute;
  top: 0;
  z-index: ${(props) => props.position * -2 + 10};
  background: ${({ position }) =>
    `rgba(${position * 8 + 0},${position * 8 + 0},${position * 8 + 0}, 1)`};
  transition: all ${({ position }) => position * 0.4 + 0.8}s
    cubic-bezier(0.24, 1, 0.32, 1);
  left: ${({ showModal, position }) => (showModal ? position * 375 : -375)}px;

  @media (max-width: 1125px) {
    width: 33.34vw;
    left: ${({ showModal, position }) =>
      showModal ? position * 33.33 : -33.33}vw;
  }

  @media (max-width: 900px) {
    width: 50vw;
    z-index: ${({ position }) => (position ? position * 2 + 10 : 100)};
    left: ${({ showModal, position }) =>
      showModal ? (position ? 50 : 0) : -50}vw;
  }

  @media (max-width: 600px) {
    width: 100vw;
    left: ${({ showModal }) => (showModal ? 0 : -100)}vw;
    z-index: ${(props) => props.position * 2 + 10};
  }
`;

export default Modal;
