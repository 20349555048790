import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      jwt
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const JOIN_SESSION_MUTATION = gql`
  mutation joinSessionRoom($sessionId: ID!, $userId: ID!) {
    joinSessionRoom(sessionId: $sessionId, userId: $userId) {
      roomName
      identity
      token
    }
  }
`;

export const POST_SESSION_MUTATION = gql`
  mutation storePostSessionAnswers($userId: ID!, $sessionId: ID!, $data: String) {
    storePostSessionAnswers(userId: $userId, sessionId: $sessionId, data: $data)
  }
`
