import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { context } from "./withSession";
import { IS_VIDEO_ENABLED, IS_AUDIO_ENABLED } from "./withTwilio";
import variables from "../variables";
import LoadingSpinner from "../components/LoadingSpinner";
import end from "../images/call-hang.png";
import vid from "../images/vid.png";
import mic from "../images/mic.png";

const _propTypes = {
  disconnectedProps: PropTypes.object.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  setSelfView: PropTypes.func.isRequired,
  twilio: PropTypes.object.isRequired,
};

const SessionControls = ({
  twilio,
  setSelfView,
  room,
  session,
  handleDisconnect,
}) => {
  const { showPostSession } = session;
  const { videoRef, participant } = useContext(context);
  const [isVideoEnabled, setVideoEnabled] = useState(IS_VIDEO_ENABLED);
  const [isAudioEnabled, setAudioEnabled] = useState(IS_AUDIO_ENABLED);
  const [isLoading, setLoading] = useState(false);

  const disableAudio = useCallback(async () => {
    participant.audioTracks.forEach(({ track }) => {
      track.disable();
      setAudioEnabled(false);
    });
  }, [participant, setAudioEnabled]);
  const enableAudio = useCallback(() => {
    participant.audioTracks.forEach(({ track }) => {
      track.enable();
      setAudioEnabled(true);
    });
  }, [participant, setAudioEnabled]);

  const disableVideo = useCallback(async () => {
    participant.videoTracks.forEach(({ track }) => {
      setSelfView(false);
      track.stop();
      // track.detach(videoRef.current); // not sure if we need to detach here. doing so affects the styles of LocalVideo
      participant.unpublishTrack(track);
      setVideoEnabled(false);
    });
  }, [participant, setSelfView, setVideoEnabled]);

  const enableVideo = useCallback(() => {
    twilio.createLocalVideoTrack().then((track) => {
      setVideoEnabled(true);
      const localVideo = document.querySelector(`#local-participant`);
      localVideo.appendChild(track.attach(videoRef.current));
      return participant.publishTrack(track);
    });
  }, [twilio, participant, videoRef]);

  const endSession = useCallback(() => {
    setLoading(true);
    disableVideo();
    disableAudio().finally(() => {
      !showPostSession && handleDisconnect();
      room.disconnect();
      setLoading(false);
    });
  }, [
    disableAudio,
    disableVideo,
    room,
    showPostSession,
    handleDisconnect,
    setLoading,
  ]);

  return (
    <Controls>
      <ControlContainer>
        <Control
          active={isAudioEnabled}
          icon={mic}
          onClick={isAudioEnabled ? disableAudio : enableAudio}
        />
        {isAudioEnabled ? `Mic On` : `Mic Off`}
      </ControlContainer>

      <ControlContainer>
        <Control
          active={isVideoEnabled}
          icon={vid}
          onClick={isVideoEnabled ? disableVideo : enableVideo}
        />
        {isVideoEnabled ? `Video On` : `Video Off`}
      </ControlContainer>

      <ControlContainer>
        <EndCall icon={end} onClick={endSession} />
        {isLoading ? <LoadingSpinner loading={isLoading} /> : "End"}
      </ControlContainer>
    </Controls>
  );
};

SessionControls.propTypes = _propTypes;

export default SessionControls;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -110px;
  width: 220px;
  z-index: 9;
`;

const ControlContainer = styled.div`
  align-items: center;
  color: ${variables.white};
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
  text-align: center;
`;

const Control = styled.div`
  background-color: ${({ active }) =>
    active ? variables.blue_secondary : variables.grey_primary};
  background-image: ${({ icon }) => `url(${icon})`};
  background-size: auto 30px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  color: ${variables.white};
  cursor: pointer;
  height: 60px;
  min-height: 60px;
  transition: background-color 200ms ease;
  width: 60px;
`;

const EndCall = styled(Control)`
  background-color: unset;
  background-size: contain;
  border-radius: 0;
`;
