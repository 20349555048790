import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import variables from "../variables";

const _propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
};

const Selector = ({ items, selectedItem, onClick }) => (
  <SelectorContainer>
    {items.map((item, index) => (
      <Item
        key={index}
        onClick={(e) => onClick(e, item)}
        selected={selectedItem.id === index}
      >
        {item.text}
      </Item>
    ))}
  </SelectorContainer>
);

Selector.propTypes = _propTypes;

export default Selector;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  background: ${({ selected }) => selected ? variables.blue_primary : variables.white};
  color: ${({ selected }) => (selected ? variables.white : variables.black)};
  cursor: pointer;
  font-size: 24px;
  margin: 0 5px 5px 0;
  padding: 6px 8px;
  user-select: none;
`;
