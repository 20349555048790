import React from "react";
import PropTypes from 'prop-types';
import variables from "../variables";
import styled from "styled-components";
import LoadingSpinner from "../components/LoadingSpinner";

const _propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  cta: PropTypes.bool,
  style: PropTypes.object,
  loading: PropTypes.bool,
};

const ButtonInline = ({
  disabled = false,
  children,
  cta,
  loading,
  onClick,
  style,
}) => (
  <Button disabled={disabled || loading} onClick={onClick} cta={cta} style={style}>
    {loading ? <LoadingSpinner loading={loading} /> : children}
  </Button>
);

ButtonInline.propTypes = _propTypes;

const Button = styled.button`
  align-items: center;
  background-color: ${(props) => props.cta ? variables.blue_primary : variables.white_primary};
  border-radius: 10px;
  color: ${props => props.cta ? variables.white : variables.black_primary};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  font-size: ${(props) => props.little ? '14px' : '24px'};
  letter-spacing: -0.5px;
  height: ${(props) => props.little ? '30px' : '40px'};
  justify-content: center;
  width: 100%;
  outline: none;
  border: none;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export default ButtonInline;
