import { useState } from "react";
import variables from "../../../variables";
const { GRACE_PERIOD_LENGTH } = variables;

const WRAP_UP_LENGTH = 120000;

const formatTime = (time) => {
  if (time < 10) return `0${time}`;
  return time;
};

export const useSessionTime = (session) => {
  const [time, setTime] = useState("");
  const sessionStart = new Date(session.date);
  const sessionEnd = new Date(session.endDate);

  setInterval(() => {
    const now = Date.now();

    const isActive = (now > sessionStart && now < new Date(sessionEnd.getTime() - WRAP_UP_LENGTH));
    const isWrapUp = (now > new Date(sessionEnd.getTime() - WRAP_UP_LENGTH) && now < sessionEnd);
    const isPostSession = (now > sessionEnd && now < new Date(sessionEnd.getTime() + GRACE_PERIOD_LENGTH));

    const timer = () => {
      if (!isNaN(sessionStart)) {
        if (isPostSession) {
          const delta = new Date(
            sessionEnd.getTime() + GRACE_PERIOD_LENGTH - now
          );

          const minutes = formatTime(delta.getUTCMinutes());
          const seconds = formatTime(delta.getUTCSeconds());
          setTime(`${minutes}:${seconds}`);
          return;
        }

        if (isWrapUp) {
          setTime(`Wrap up`);
          return;
        }

        if (isActive) {
          const delta = new Date(now - sessionStart);

          const hours   = formatTime(delta.getUTCHours());
          const minutes = formatTime(delta.getUTCMinutes());
          const seconds = formatTime(delta.getUTCSeconds());
          setTime(`${hours}:${minutes}:${seconds}`);
          return;
        }
      }
    };

    timer();
  }, 1000);

  if (!time) return {};

  return {
    time,
    isPostSession: 
      Date.now() > sessionEnd && 
      Date.now() < new Date(sessionEnd.getTime() + GRACE_PERIOD_LENGTH)
  };
};
