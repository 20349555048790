import React from "react";
import PropTypes from "prop-types";
import variables from "../variables";
import styled from "styled-components";
import LoadingSpinner from "../components/LoadingSpinner";

const _propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const MenuDetail = ({ loading = false, title, value }) => (
  <Row>
    <span>{title}</span>
    {loading ? (
      <LoadingSpinner loading={loading} size="small" />
    ) : (
      <span id="menu-detail-value">{value}</span>
    )}
  </Row>
);

MenuDetail.propTypes = _propTypes;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${variables.white_primary};
  font-size: 16px;
  height: 40px;
  align-items: center;
  
  #menu-detail-value {
    width: 20px;
  }
`;

export default MenuDetail;
