import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SphereLogo from "./SphereLogo";
import MenuIcon from "../images/menu.png";
import variables from "../variables";

const { HEADER_HEIGHT } = variables;

const _propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
};

const Navigation = ({ setShowModal, showModal, isVisible = true }) => {
  return !isVisible ? null : (
    <Container>
      <SphereLogo />
      <Menu onClick={() => setShowModal(!showModal)}>
        <img src={MenuIcon} alt="Menu Icon" />
      </Menu>
    </Container>
  );
};

Navigation.propTypes = _propTypes;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  border: none;
  background: none;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div`
  width: 30px;
  height: 31px;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
  }
`;

export default Navigation;
