import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withSession from "./withSession";
import variables from "../variables";
import ParticipantInfo from "./ParticipantInfo";
import { useSessionTime } from "../screens/Lobby/hooks/useSessionTime";

const ParticipantRemote = ({
  isVideoSubscribed,
  participant,
  session,
  videoRef,
  hasDisconnected,
}) => {
  const { isPostSession, time } = useSessionTime(session);

  if (hasDisconnected) return null;

  if (!isVideoSubscribed) {
    return (
      <ParticipantInfo
        isVideoSubscribed={isVideoSubscribed}
        session={session}
      />
    );
  }

  return (
    <>
      <Info>
        <Name>{session.counterpart}</Name>
        <Time isPostSession={isPostSession}>{time}</Time>
      </Info>

      <RemoteVideo
        key={participant.sid}
        ref={videoRef}
        id="remote-participant"
      />
    </>
  );
};

ParticipantRemote.propTypes = {
  videoRef: PropTypes.object.isRequired,
};

const RemoteVideo = styled.video`
  min-height: 100%;
  min-width: auto;
  transition: all 300ms ease;
  transform: translateX(calc((100% - 100vw) / 2));
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 20px;
`;

const Info = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  width: 100px;
  z-index: 3;
  position: absolute;
  top: 10%;
  right: 0;
  left: 0;
`;

const Name = styled.h1`
  color: ${variables.white};
  font-size: 24px;
`;

const Time = styled.h3`
  color: ${({ isPostSession }) => isPostSession ? variables.error : variables.grey_primary};
  font-size: 16px;
  font-weight: 100;
  margin: 0;
`;

export default withSession(ParticipantRemote);
