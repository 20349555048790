import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import variables from "../variables";
import { useSessionTime } from "../screens/Lobby/hooks/useSessionTime";

const placeholder = require('../images/placeholder-img.png');

const IMAGE_SIZE = 200;

const _propTypes = {
  isVideoSubscribed: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  waiting: PropTypes.bool,
};

const ParticipantInfo = ({ isVideoSubscribed, session, waiting }) => {
  const { isPostSession, time } = useSessionTime(session);
  const { counterpart, counterpartImage } = session;

  if (isVideoSubscribed) return null;

  return (
    <ImageContainer>
      {counterpartImage ? (
        <Image src={counterpartImage} />
      ) : (
        <Placeholder src={placeholder} />
      )}
      {counterpart && <Text>{waiting ? `Waiting for ${counterpart}...` : counterpart}</Text>}
      {!waiting && (
        <Time isPostSession={isPostSession}>{time}</Time>
      )}
    </ImageContainer>
  );
};

ParticipantInfo.propTypes = _propTypes;

export default ParticipantInfo;

const pulsate = keyframes`
  0%   { border-color: ${variables.black}; }
  50%  { border-color: ${variables.black_secondary}; }
  100% { border-color: ${variables.black}; }
`;

const ImageContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
`;

const Placeholder = styled.div`
  animation: ${pulsate} 8s infinite;
  background: ${({ src }) => `center / contain no-repeat url(${src})`};
  border: 10px solid ${variables.grey_secondary};
  border-radius: 50px;
  height: 100px;
  margin-top: -${IMAGE_SIZE * 1.5}px;
  width: 100px;
`;

const Image = styled.div`
  animation: ${pulsate} 5s infinite;
  background: ${({ src }) => `center / cover no-repeat url(${src})`};
  border: 20px solid ${variables.grey_secondary};
  border-radius: ${IMAGE_SIZE / 2}px;
  display: flex;
  height: ${IMAGE_SIZE}px;
  margin-top: -${IMAGE_SIZE * 1.5}px;
  width: ${IMAGE_SIZE}px;
`;

const Text = styled.h1`
  color: ${variables.white};
  font-size: 24px;
  text-align: center;
`;

const Time = styled.h3`
  color: ${({ isPostSession }) => isPostSession ? variables.error : variables.grey_primary};
  font-size: 16px;
  font-weight: 100;
  margin: 0;
`;