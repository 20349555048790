import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import variables from "../variables";
import Button from "./Button";
import Selector from "./Selector";
import TextInput from "./TextInput";
import { H4, H3 } from "./Typography";
import { useStorePostSession } from "../network/hooks";

const { breakpoints: { small } } = variables;

const _propTypes = {
  handleDisconnect: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

const selectorOptions = [
  { id: 0, text: "Good" },
  { id: 1, text: "Not Great" },
];

const PostSession = ({ ...props }) => (
  <Container>
    <PostSessionContent {...props} />
  </Container>
);

const PostSessionContent = ({ handleDisconnect, session }) => {
  const [selected, setSelected] = useState({});
  const [message, setMessage] = useState("");
  const { storePostSession, loading } = useStorePostSession();
  const { type, id, seeker, guide } = session;
  const userId = type === "Seekers" ? seeker.id : guide.id;

  const fadeAndDisconnect = useCallback(() => {
    const element = document.querySelector("#post-session-content");

    if (element) {
      setTimeout(() => {
        element.style.opacity = 0;
      }, 500);
    };

    setTimeout(() => {
      handleDisconnect();
    }, 1000);
  }, [handleDisconnect]);

  const handleSelect = useCallback((e, item) => {
    e.preventDefault();
    setSelected(item);
  }, []);

  const handleSubmit = useCallback(() => {
    storePostSession({
      variables: {
        userId,
        sessionId: id,
        data: JSON.stringify({
          answer: selected.text,
          reason: message.trim()
        })
      }
    })
    .finally(() => {
      fadeAndDisconnect();
    });
  }, [fadeAndDisconnect, storePostSession, id, userId, selected, message]);

  const onChangeText = useCallback(
    ({ target: { value } }) => {
      setMessage(value);
  }, [setMessage]);

  useEffect(() => {
    if (selected.id === 0) {
      storePostSession({
        variables: {
          userId,
          sessionId: id,
          data: JSON.stringify({
            answer: selected.text,
          })
        }
      })
      .finally(() => {
        fadeAndDisconnect();
      });
    } 
    
    // Selected "Not great"
    if (selected.id === 1) {
      const element = document.querySelector("#post-session-input");

      if (element) {
        element.style.opacity = 1;

        return () => {
          element.style.opacity = 0;
        }
      }
    }
  }, [fadeAndDisconnect, storePostSession, selected, userId, id]);

  useEffect(() => {
    const element = document.querySelector("#post-session-button-container");

    if (element) {
      const opacity = Number(element.style.opacity);
      // trying to avoid useless updates
      if (!message.length && opacity) element.style.opacity = 0;
      if (message.length && !opacity) element.style.opacity = 1; 
    }
  }, [message]);

  return (
    <Content id="post-session-content">
      <H3>{type === "Seekers" ? `How was your session?` : `How was the call quality of your session?`}</H3>
      {type === "Seekers" && (
        <H4 style={{ color: variables.grey_secondary, fontSize: 16, marginTop: -10 }}>
          {`This won't be shared with your guide.`}
        </H4>
      )}

      <div style={{ width: '100%' }}>
        <Selector items={selectorOptions} onClick={handleSelect} selectedItem={selected} />

        <InputContainer id="post-session-input" disabled={selected.id !== 1}>
          <TextInput 
            attrs={{ autoFocus: true, placeholder: "Please tell us why..." }}
            onChange={onChangeText}
            value={message}
          />
          {type === "Seekers" && (
            <H4 style={{ fontSize: 16, marginTop: -10 }}>{`Your guide won't see this.`}</H4>
          )}
        </InputContainer>
        
        <ButtonContainer id="post-session-button-container" disabled={!message.length}>
          <Button cta disabled={loading} loading={loading} onClick={handleSubmit}>Done</Button>
        </ButtonContainer>
      </div>
    </Content>
  );
};

PostSessionContent.propTypes = _propTypes;

export default PostSession;

const Container = styled.div`
  align-items: flex-start;
  background: ${variables.black};
  color: ${variables.white};
  display: flex;
  flex-direction: column;
  height: 100%;

  @media(min-width: ${small}px) {
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 20px 0;
  opacity: 1;
  transition: opacity 500ms ease-in;

  @media(min-width: ${small}px) {
    margin: auto;
    width: auto;
  }
`;

const InputContainer = styled.div`
  margin-top: 40px;
  opacity: 0;
  transition: opacity 500ms ease-out;
  width: 100%;

  & > * {
    visibility: ${({ disabled }) => disabled ? 'hidden' : 'auto'};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 500ms ease-out;
  width: 100%;

  & > * {
    cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
    visibility: ${({ disabled }) => disabled ? 'hidden' : 'auto'};
  }
`;