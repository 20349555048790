import React, { useContext } from 'react';
import styled from 'styled-components';
import logo from '../images/sphere-logo.png';
import { AppContext } from '../context';

const SphereLogo = () => {
  const { isIframe } = useContext(AppContext);
  return <Sphere show={!isIframe} src={logo} alt="Sphere Logo" />
}

const Sphere = styled.img`
  opacity: ${({ show }) => show ? 1 : 0};
  pointer-events: ${({ show }) => show ? 'all' : 'none'};
  height: 40px;
`;

export default SphereLogo;