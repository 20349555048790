import React, { useCallback, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import get from "lodash/get";
import LoadingSpinner from "../../components/LoadingSpinner";
import Navigation from "../../components/Navigation";
import Modal from "../../components/Modal";
import Session from "../../components/Session";
import variables from "../../variables";
import { Error } from "../../components/Typography";
import { useSession } from "./hooks/useSession";
import { useJoinSession } from "../../network/hooks";
import { AppContext } from "../../context";

const Lobby = () => {
  const [showModal, setShowModal] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [hasJoinedSession, setJoinedSession] = useState(false);
  const { isLoading } = useContext(AppContext);
  const session = useSession();
  const { called, data, error, joinSession, sessionLoading } = useJoinSession(hasJoinedSession);
  const counterpart = get(session, "counterpart", "");
  const isActive = get(session, "isActive", false);
  const startTime = get(session, "formattedStartTime", "");
  const startDate = get(session, "formattedDate", "");

  // On Render Animation
  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  const handleJoinSession = useCallback(() => {
    joinSession();
    setJoinedSession(true);
  }, [joinSession, setJoinedSession]);

  const sessionText = session.invalid
    ? `You do not have any sessions today`
    : isActive
    ? `Your session with ${counterpart} is ready for you`
    : `You have an upcoming session with ${counterpart} on ${startDate} at ${startTime}`;

  return (
    <Container isRendered={isRendered}>
      <Modal showModal={showModal} setShowModal={setShowModal} />

      <Navigation
        isVisible={!hasJoinedSession}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      {hasJoinedSession && data ? (
        <Session
          hasJoinedSession={hasJoinedSession}
          loading={sessionLoading}
          roomData={data}
          session={session}
          setJoinedSession={setJoinedSession}
        />
      ) : (
        <Information>
          {isLoading ? (
            <>
              <SessionText>Loading...</SessionText>
              <LoadingSpinner loading={isLoading} size="large"></LoadingSpinner>
            </>
          ) : (
            <>
              <SessionText>{sessionText}</SessionText>
              {isActive && (
                <Join title="Join Sphere Session" onClick={handleJoinSession}>
                  Join Session
                </Join>
              )}
              {called && error && <Error>{error}</Error>}
            </>
          )}
        </Information>
      )}
    </Container>
  );
};

Lobby.propTypes = {};

export default Lobby;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  opacity: ${({ isRendered }) => (isRendered ? 1 : 0)};
  transition: opacity 2.4s cubic-bezier(0.24, 1, 0.32, 1);
  overflow: auto;
`;

const Information = styled.div`
  align-items: center;
  color: ${variables.white};
  display: flex;
  flex-direction: column;
  font-size: 24px;
  height: 100%;
  justify-content: center;
`;

const SessionText = styled.p`
  font-size: 24px;
  margin: 0;
  text-align: center;
`;

const Join = styled.a`
  color: ${variables.white};
  cursor: pointer;
  margin: 10px;
  text-decoration: underline;
`;
