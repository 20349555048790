import { useCallback, useEffect } from "react";
import get from "lodash/get";
import variables from '../../../variables';
import { useCurrentUser } from '../../../network/hooks';
const { USER_POLLING_INTERVAL, GRACE_PERIOD_LENGTH, PRE_SESSION_JOIN_LENGTH } = variables;

export const useSession = () => {
  const currentUser = useCurrentUser();
  const nextSessions = get(currentUser, "data.nextSessions", []);

  const getSession = useCallback(() => {
    const session = nextSessions.length
      ? nextSessions[0]
      : { loading: currentUser.loading, isActive: false, invalid: true };
    const userType = get(session, "type", "Seekers");

    if (session.__typename === 'Session') {
      const { date, endDate, length } = session;
      const now = new Date().getTime();
      const sessionEnd = new Date(endDate).getTime();
      const sessionStart = new Date(date).getTime();
      const isActive = now >= sessionStart - PRE_SESSION_JOIN_LENGTH && now <= sessionEnd + GRACE_PERIOD_LENGTH;
      const hours24 = new Date(date).getHours();
      const hours12 = hours24 % 12;
      const minutes = new Date(date).getMinutes();
      const ampm = hours24 >= 12 ? "pm" : "am";
      session.formattedDate = new Date(date).toLocaleString('locale',{weekday:'long', month:'long', day:'numeric'})
      session.isActive = isActive;
      session.formattedStartTime = `${hours12 ? hours12 : 12}:${minutes < 10 ? `0${minutes}` : minutes}${ampm}`;
      session.loading = currentUser.loading;
      session.showPostSession = now >= sessionEnd - (length / 2 * 60000);
      session.counterpart = get(
        session,
        userType === "Seekers" ? "guide.first_name" : "seeker.name",
        ""
      );
      session.counterpartImage = get(
        session,
        userType === "Seekers" ? "guide.image" : null
      );
    }

    return session;
  }, [nextSessions, currentUser]);

  useEffect(() => {
    const interval = setInterval(() => getSession(), USER_POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [getSession]);

  return getSession();
};
