const uris = {
  development: "https://api.staging.sphere.guide/graphql",
  staging: "https://api.staging.sphere.guide/graphql",
  production: "https://api.sphere.guide/graphql"
};

const firebase = {
  production: {
    apiKey: "AIzaSyB7DNVM19IrLPBao0cGAFlvopPeR9xpBDA",
    authDomain: "sphere-86dd8.firebaseapp.com",
    databaseURL: "https://sphere-86dd8.firebaseio.com",
    projectId: "sphere-86dd8",
    storageBucket: "sphere-86dd8.appspot.com",
    messagingSenderId: "117906963069",
    appId: "1:117906963069:web:32978f6dbeb07421a2cf14"
  },
  staging: {
    apiKey: "AIzaSyBDlj9R6562COtq3PNyeGmM9TY-hgF0aDI",
    authDomain: "sphere-46642.firebaseapp.com",
    databaseURL: "https://sphere-46642.firebaseio.com",
    projectId: "sphere-46642",
    storageBucket: "sphere-46642.appspot.com",
    messagingSenderId: "1060118281603",
    appId: "1:1060118281603:web:0cfd267746d851baa14ab6"
  },
  development: {
    apiKey: "AIzaSyBDlj9R6562COtq3PNyeGmM9TY-hgF0aDI",
    authDomain: "sphere-46642.firebaseapp.com",
    databaseURL: "https://sphere-46642.firebaseio.com",
    projectId: "sphere-46642",
    storageBucket: "sphere-46642.appspot.com",
    messagingSenderId: "1060118281603",
    appId: "1:1060118281603:web:0cfd267746d851baa14ab6"
  }
};

module.exports = {
  getUri: env => uris[env],
  getFirebase: env => firebase[env],
};

